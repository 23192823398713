import './style.css';
import MainLayout from "../../layout/mainLayout";
import Form from "../../components/form";

function MenuItemList() {
    return (<>
        <li>
            <a href="#letter">
                Открытое письмо
            </a>
        </li>

        <li>
            <a href="#how-it-work">
                Условия получения гранта
            </a>
        </li>
        <li>
            <a href="#grant">
                Подать заявку
            </a>
        </li>
        <li>
            <a href="/">
                Курс Java-разработчик
                <span className="link-badge text-badge">New</span>
            </a>
        </li>
    </>);
}

function GrantFormPage() {


    return (
        <MainLayout menuItemList={MenuItemList()}>
            <div style={{marginTop:"100px"}}></div>
            {/*<Disclaimer*/}
            {/*    title={'Курс запускается потоками'}*/}
            {/*    description={<>*/}
            {/*        При запуске нового потока мы обновляем курс,*/}
            {/*        что бы вы получили навыки, которые прямо сейчас нужны в реальной работе.*/}

            {/*        Последняя дата обновления - <span className={'disclaimer-text-important'}>сентябрь 2023 года.</span>*/}
            {/*    </>}/>*/}
            <div
                className="module-sections relative flex flex-col items-center gap-60 rounded-top-100 pt-100 pb-50 px-10 bg-top-center bg-cover bg-no-repeat transition-all shadow-top lg:border-radius-0 lg:py-0"
                style={{"background-image": "url(./assets/images/demo/start-hub-x/bg/page-bg.svg);"}}
            >

                <Form
                    tagTitle={'Форма'}
                    title={'Заполни форму ниже'}
                    subtitle={<>Заполнение формы является обязательным, для рассмотрения гранта</>}

                />


            </div>
        </MainLayout>
    );
};

export default GrantFormPage;
