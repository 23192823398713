import './price.css'

function Prices() {

    return (<>
            <div id={'prices'}
                className="ld-fancy-heading relative flex self-center text-center bg-transparent rounded-100 animation-element"
                style={{"background-image": "linear-gradient(90deg, #E3C4E9 0%, #EAEDFB 100%)"}}>
                <h6 className="ld-fh-element inline-block relative py-5 px-15 m-0">
                    Цены • Планы</h6>
            </div>
            <div className="ld-fancy-heading relative flex text-center animation-element">
                <h2 className="ld-fh-element inline-block relative ld-gradient-heading bg-transparent mb-0/2em pb-0/2em"
                    style={{"background-image": "linear-gradient(180deg, #7F5BC4 0%, #60A3E6 100%)"}}>
                    Стоимость обучения
                </h2>
            </div>
            <div className="lqd-tabs-content relative">
                <div id="lqd-tab-pricing-monthly" role="tabpanel"
                     className="lqd-tabs-pane fade active show">
                    <div className="pt-50 pb-20 px-20 lg:p-0 module-sections">
                        <div className="container p-0">
                            <div className="row m-0">
                                <div className="co-12 col-md-4 p-0 price-col">
                                    <div
                                        className="module-section mx-20 flex flex-wrap bg-white rounded-10 border-0  border-gray-100 shadow-sm transition-all price-col-shadow">
                                        <div
                                            className="w-full flex flex-col items-center border-bottom  border-gray-100 transition-all pt-15 pb-5 px-35">
                                            <div className="mb-20 ld-fancy-heading relative">
                                                <p className="font-title text-16 font-bold leading-1 tracking-0 -mb-1em text-amber-600 ld-fh-element inline-block relative price-name">
                                                    Базовый <span className={'price-name-package'}>план</span>
                                                </p>
                                            </div>
                                            <div className="ld-fancy-heading relative">
                                                <h3 className="font-title text-46 font-bold mb-20 ld-fh-element inline-block relative">
                                                    499 ₽
                                                    <small className="font-bold text-13 text-sky-900">
                                                        /мес
                                                    </small>
                                                </h3>
                                            </div>
                                        </div>
                                        <div
                                            className="module-section-bottom w-full flex flex-col py-30 px-55 items-center text-center">
                                            <div
                                                className="mb-20 ld-fancy-heading relative lg:text-start">
                                                <p className="text-16 leading-1/5em mb-0/5em text-secondary ld-fh-element inline-block relative">
                                                    Программа обучения Java разработчика с форматами обучения:
                                                </p>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="iconbox-icon-container inline-flex relative z-1 rounded-full w-25 h-25 text-14 text-amber-600 bg-amber-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">
                                                    Онлайн-лекции
                                                </h3>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="iconbox-icon-container inline-flex relative z-1 rounded-full w-25 h-25 text-14 text-amber-600 bg-amber-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">
                                                    Видеозаписи занятий
                                                </h3>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="iconbox-icon-container inline-flex relative z-1 rounded-full w-25 h-25 text-14 text-amber-600 bg-amber-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">
                                                    Вебинары
                                                </h3>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="iconbox-icon-container inline-flex relative z-1 rounded-full w-25 h-25 text-14 text-amber-600 bg-amber-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">
                                                    Практические проекты
                                                </h3>
                                            </div>
                                            <a href="https://campus.zenclass.ru/public/product/364d3afd-17d3-4e57-beb7-cd56f0121ae5/tariffs"
                                               className="btn btn-solid btn-sm btn-block btn-icon-right btn-hover-reveal module-btn-1 text-15 mt-15 rounded-6 font-medium leading-2em bg-red-100 text-amber-600"
                                               target="_blank">
                                                <span className="btn-txt" data-text="Join Simple Plan">
                                                    Попробовать
                                                </span>
                                                <span className="btn-icon">
                                                    <i aria-hidden="true"
                                                       className="lqd-icn-ess icon-md-arrow-forward"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="co-12 col-md-4 p-0 price-col">
                                    <div
                                        className="module-section mx-20 flex flex-wrap bg-white rounded-10 border-0  border-gray-100 shadow-sm transition-all price-col-shadow">
                                        <div
                                            className="w-full flex flex-col items-center border-bottom  border-gray-100 transition-all pt-15 pb-5 px-35">
                                            <div className="mb-20 ld-fancy-heading relative">
                                                <p className="font-title text-16 font-bold leading-1 tracking-0 -mb-1em text-blue-800 ld-fh-element inline-block relative price-name">
                                                    Расширенный <span className={'price-name-package'}>план</span>
                                                </p>
                                            </div>
                                            <div className="ld-fancy-heading relative">
                                                <h3 className="font-title text-46 font-bold mb-20 ld-fh-element inline-block relative">1
                                                    490₽ <small
                                                        className="font-bold text-13 text-sky-900">/мес</small>
                                                </h3>
                                            </div>
                                        </div>
                                        <div
                                            className="module-section-bottom w-full flex flex-col py-30 px-55 items-center text-center">
                                            <div
                                                className="mb-20 ld-fancy-heading relative lg:text-start">
                                                <p className="text-16 leading-1/5em mb-0/5em text-secondary ld-fh-element inline-block relative">
                                                    Вся программа из базового курса плюс стажировка на проектах:
                                                </p>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="w-25 h-25 icon-blue iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-blue-800 bg-blue-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">
                                                    "Маленькие люди"
                                                </h3>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="w-25 h-25 icon-blue iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-blue-800 bg-blue-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">
                                                    FoodTech Lab
                                                </h3>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="w-25 h-25 icon-blue iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-blue-800 bg-blue-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">
                                                    BlinBerry Group
                                                </h3>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="w-25 h-25 icon-blue iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-blue-800 bg-blue-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">
                                                    HR помощь
                                                </h3>
                                            </div>
                                            <a href="https://campus.zenclass.ru/public/product/364d3afd-17d3-4e57-beb7-cd56f0121ae5/tariffs"
                                               className="btn btn-solid btn-sm btn-block btn-icon-right btn-hover-reveal text-15 mt-15 rounded-6 font-medium leading-2em text-white bg-blue-500 module-btn-2"
                                               target="_blank" rel="nofollow">
                                                <span className="btn-txt" data-text="Join Standard Plan">
                                                    Тестовый урок
                                                </span>
                                                <span className="btn-icon">
                                                    <i aria-hidden="true"
                                                       className="lqd-icn-ess icon-md-arrow-forward"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="co-12 col-md-4 p-0 price-col soldout">
                                    <div
                                        className="module-section mx-20 flex flex-wrap bg-white rounded-10 border-0  border-gray-100 shadow-sm transition-all price-col-shadow ">
                                        <div
                                            className="w-full flex flex-col items-center border-bottom  border-gray-100 transition-all pt-15 pb-5 px-35">
                                            <div className="mb-20 ld-fancy-heading relative">
                                                <p className="font-title text-16 font-bold leading-1 tracking-0 -mb-1em text-emerlad-500 ld-fh-element inline-block relative price-name">
                                                    Индивидуальный <span className={'price-name-package'}>план</span>
                                                </p>
                                            </div>
                                            <div className="ld-fancy-heading relative">
                                                <h3 className="font-title text-46 font-bold mb-20 ld-fh-element inline-block relative">
                                                    14 990
                                                    <small className="font-bold text-13 text-sky-900">/мес</small>
                                                </h3>
                                            </div>
                                        </div>
                                        <div
                                            className="module-section-bottom w-full flex flex-col py-30 px-55 items-center text-center">
                                            <div className="mb-20 ld-fancy-heading relative lg:text-start">
                                                <p className="text-16 leading-1/5em mb-0/5em text-secondary ld-fh-element inline-block relative">
                                                    Вся программа расширенного пакета вместе с индивидуальной помощью:
                                                </p>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="w-25 h-25 icon-green iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-emerlad-500 bg-green-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">
                                                    Менторство
                                                </h3>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="w-25 h-25 icon-green iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-emerlad-500 bg-green-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">
                                                    Закрытые вебинары
                                                </h3>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="w-25 h-25 icon-green iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-emerlad-500 bg-green-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">
                                                    40 часов консультаций
                                                </h3>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="w-25 h-25 icon-green iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-emerlad-500 bg-green-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">
                                                    Входящий ассесмент
                                                </h3>
                                            </div>
                                            <div className={'soldout'}>
                                                Sold-Out 😱
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="lqd-tab-pricing-annual" role=" tabpanel" className="lqd-tabs-pane fade">
                    <div className="pt-50 pb-20 px-20 lg:p-0 module-sections">
                        <div className="container p-0">
                            <div className="row m-0">
                                <div className="co-12 col-md-4 p-0">
                                    <div
                                        className="module-section mx-20 flex flex-wrap bg-white rounded-10 border-0  border-gray-100 shadow-sm transition-all">
                                        <div
                                            className="w-full flex flex-col items-center border-bottom  border-gray-100 transition-all pt-15 pb-5 px-35">
                                            <div className="mb-20 ld-fancy-heading relative">
                                                <p className="font-title text-16 font-bold leading-1 tracking-0 -mb-1em text-amber-600 ld-fh-element inline-block relative">Simple
                                                    Plan</p>
                                            </div>
                                            <div className="ld-fancy-heading relative">
                                                <h3 className="font-title text-46 font-bold mb-20 ld-fh-element inline-block relative">$10 <small
                                                    className="font-bold text-13 text-sky-900">/yr</small>
                                                </h3>
                                            </div>
                                        </div>
                                        <div
                                            className="module-section-bottom w-full flex flex-col py-30 px-55 items-center text-center">
                                            <div
                                                className="mb-20 ld-fancy-heading relative lg:text-start">
                                                <p className="text-16 leading-1/5em mb-0/5em text-secondary ld-fh-element inline-block relative">Use
                                                    customer data to build great and solid product </p>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="iconbox-icon-container inline-flex relative z-1 rounded-full w-25 h-25 text-14 text-amber-600 bg-amber-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">99.9
                                                    Uptime Guarantee </h3>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="iconbox-icon-container inline-flex relative z-1 rounded-full w-25 h-25 text-14 text-amber-600 bg-amber-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">Unlimited
                                                    Storage </h3>
                                            </div>
                                            <a href="./page-start-hub-1-pricing.html"
                                               className="btn btn-solid btn-sm btn-block btn-icon-right btn-hover-reveal text-15 mt-15 rounded-6 font-medium leading-2em bg-red-100 text-amber-600 module-btn-1"
                                               target="_blank" rel="nofollow">
                                                <span className="btn-txt"
                                                      data-text="Join Simple Plan">Join Simple Plan</span>
                                                <span className="btn-icon">
																					<i aria-hidden="true"
                                                                                       className="lqd-icn-ess icon-md-arrow-forward"></i>
																				</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="co-12 col-md-4 p-0">
                                    <div
                                        className="module-section mx-20 flex flex-wrap bg-white rounded-10 border-0  border-gray-100 shadow-sm transition-all">
                                        <div
                                            className="w-full flex flex-col items-center border-bottom  border-gray-100 transition-all pt-15 pb-5 px-35">
                                            <div className="mb-20 ld-fancy-heading relative">
                                                <p className="font-title text-16 font-bold leading-1 tracking-0 -mb-1em text-blue-800 ld-fh-element inline-block relative">Standard
                                                    Plan</p>
                                            </div>
                                            <div className="ld-fancy-heading relative">
                                                <h3 className="font-title text-46 font-bold mb-20 ld-fh-element inline-block relative">$30 <small
                                                    className="font-bold text-13 text-sky-900">/yr</small>
                                                </h3>
                                            </div>
                                        </div>
                                        <div
                                            className="module-section-bottom w-full flex flex-col py-30 px-55 items-center text-center">
                                            <div
                                                className="mb-20 ld-fancy-heading relative lg:text-start">
                                                <p className="text-16 leading-1/5em mb-0/5em text-secondary ld-fh-element inline-block relative">Use
                                                    customer data to build great and solid product </p>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="w-25 h-25 icon-blue iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-blue-800 bg-blue-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">99.9
                                                    Uptime Guarantee </h3>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="w-25 h-25 icon-blue iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-blue-800 bg-blue-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">Unlimited
                                                    Storage </h3>
                                            </div>
                                            <a href="./page-start-hub-1-pricing.html"
                                               className="btn btn-solid btn-sm btn-block btn-icon-right btn-hover-reveal text-15 mt-15 rounded-6 font-medium leading-2em text-white bg-blue-500 module-btn-2"
                                               target="_blank" rel="nofollow">
                                                                <span className="btn-txt"
                                                                      data-text="Join Standard Plan">Join Standard Plan</span>
                                                <span className="btn-icon">
																					<i aria-hidden="true"
                                                                                       className="lqd-icn-ess icon-md-arrow-forward"></i>
																				</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="co-12 col-md-4 p-0">
                                    <div
                                        className="module-section mx-20 flex flex-wrap bg-white rounded-10 border-0  border-gray-100 shadow-sm transition-all">
                                        <div
                                            className="w-full flex flex-col items-center border-bottom  border-gray-100 transition-all pt-15 pb-5 px-35">
                                            <div className="mb-20 ld-fancy-heading relative">
                                                <p className="font-title text-16 font-bold leading-1 tracking-0 -mb-1em text-emerlad-500 ld-fh-element inline-block relative">Premium
                                                    Plan</p>
                                            </div>
                                            <div className="ld-fancy-heading relative">
                                                <h3 className="font-title text-46 font-bold mb-20 ld-fh-element inline-block relative">$40 <small
                                                    className="font-bold text-13 text-sky-900">/yr</small>
                                                </h3>
                                            </div>
                                        </div>
                                        <div
                                            className="module-section-bottom w-full flex flex-col py-30 px-55 items-center text-center">
                                            <div
                                                className="mb-20 ld-fancy-heading relative lg:text-start">
                                                <p className="text-16 leading-1/5em mb-0/5em text-secondary ld-fh-element inline-block relative">Use
                                                    customer data to build great and solid product </p>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="w-25 h-25 icon-green iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-emerlad-500 bg-green-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">99.9
                                                    Uptime Guarantee </h3>
                                            </div>
                                            <div
                                                className="w-full mb-20 -mt-5 iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-circle">
                                                <div className="iconbox-icon-wrap mr-15">
                                                    <div
                                                        className="w-25 h-25 icon-green iconbox-icon-container inline-flex relative z-1 rounded-full text-14 text-emerlad-500 bg-green-100">
                                                        <i aria-hidden="true"
                                                           className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                                                    </div>
                                                </div>
                                                <h3 className="text-14 font-normal m-0 text-secondary lqd-iconbox-heading">Unlimited
                                                    Storage </h3>
                                            </div>
                                            <a href="./page-start-hub-1-pricing.html"
                                               className="btn btn-solid btn-sm btn-block btn-icon-right btn-hover-reveal text-15 mt-15 rounded-6 font-medium leading-2em bg-green-100 text-emerlad-500 module-btn-3"
                                               target="_blank" rel="nofollow">
                                                <span className="btn-txt" data-text="Join Premium Plan">Join Premium Plan</span>
                                                <span className="btn-icon">
																					<i aria-hidden="true"
                                                                                       className="lqd-icn-ess icon-md-arrow-forward"></i>
																				</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    );
}

export default Prices;