import './offer.css'


function Offer(
    {
        titleFirstLine,
        titleSecondLine,
        subtitle,
        actionTitle,
        actionLink,
        bottomTitle,
        img1,img2,img3
    }
) {
    return (<section
        className="lqd-section banner min-h-100vh flex items-stretch justify-center bg-center bg-cover bg-no-repeat px-10 top-0 sm:justify-start"
        id="offer"
        style={{"background-image": "url(./assets/images/demo/start-hub-x/banner/bg1.jpg)"}}>
        <div className="w-full min-h-100vh flex flex-col justify-center items-center py-10"
             data-parallax="true"
             data-parallax-options='{"ease": "linear", "start": "top top", "end": "bottom top"}'
             data-parallax-from='{"y": "0vh", "scale": 1 }'
             data-parallax-to='{"y": "70vh", "scale": 1.5}'>
            <div
                className="w-500 max-w-full flex flex-wrap transition-bg gap-5 p-10 mt-130 sm:w-full lg:m-0"
                // data-custom-animations="false"
                data-ca-options='{"animationTarget": ".animation-element", "duration" : 850, "delay" : 100, "ease": "expo.out", "initValues": {"opacity" : 0, "y" : 150} , "animations": {"opacity" : 1, "y" : 0}}'>
                <div
                    className="ld-fancy-heading relative flex text-center max-w-full animation-element">
                    <h2 className="ld-fh-element inline-block relative ld-gradient-heading lqd-highlight-custom lqd-highlight-custom-2 bg-transparent text-80 font-bold -tracking-3/5 mb-0/2em pb-0/2em"
                        data-inview="true" data-transition-delay="true"
                        data-delay-options='{"elements": ".lqd-highlight-inner", "delayType": "transition"}'
                        style={{"background-image": "linear-gradient(180deg, #2E56A7 0%, #BB6DE5 100%)"}}>
                        <span>{titleFirstLine}</span>

                        <mark className="lqd-highlight" style={{'display':"block"}}>
                            <span className="lqd-highlight-txt">{titleSecondLine}</span>
                            <span className="lqd-highlight-inner left-0 -bottom-0/2em">
												<svg className="lqd-highlight-pen" width="51" height="51"
                                                     viewBox="0 0 51 51" xmlns="http://www.w3.org/2000/svg">
													<path
                                                        d="M36.204 1.044C32.02 2.814 5.66 31.155 4.514 35.116c-.632 2.182-1.75 5.516-2.483 7.409-3.024 7.805-1.54 9.29 6.265 6.265 1.893-.733 5.227-1.848 7.41-2.477 3.834-1.105 4.473-1.647 19.175-16.27 0 0 10.63-10.546 15.21-15.125C53 8.997 42.021-1.418 36.203 1.044Zm7.263 5.369c3.56 3.28 4.114 4.749 2.643 6.995l-1.115 1.7-4.586-4.543-4.585-4.544 1.42-1.157C39.311 3.18 40.2 3.4 43.467 6.413ZM37.863 13.3l4.266 4.304-11.547 11.561-11.547 11.561-4.48-4.446-4.481-4.447 11.404-11.418c6.273-6.28 11.566-11.42 11.762-11.42.197 0 2.277 1.938 4.623 4.305ZM12.016 39.03l3.54 3.584-3.562 1.098-5.316 1.641c-1.665.516-1.727.455-1.211-1.21l1.614-5.226c1.289-4.177.685-4.191 4.935.113Z"></path>
												</svg>
												<svg className="lqd-highlight-brush-svg lqd-highlight-brush-svg-2"
                                                     width="233" height="13" viewBox="0 0 233 13"
                                                     xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                                                     preserveAspectRatio="none">
													<path
                                                        d="m.624 9.414-.312-2.48C0 4.454.001 4.454.002 4.454l.035-.005.102-.013.398-.047c.351-.042.872-.102 1.557-.179 1.37-.152 3.401-.368 6.05-.622C13.44 3.081 21.212 2.42 31.13 1.804 50.966.572 79.394-.48 113.797.24c34.387.717 63.927 2.663 84.874 4.429a1048.61 1048.61 0 0 1 24.513 2.34 641.605 641.605 0 0 1 8.243.944l.432.054.149.02-.318 2.479-.319 2.48-.137-.018c-.094-.012-.234-.03-.421-.052a634.593 634.593 0 0 0-8.167-.936 1043.26 1043.26 0 0 0-24.395-2.329c-20.864-1.76-50.296-3.697-84.558-4.413-34.246-.714-62.535.332-82.253 1.556-9.859.612-17.574 1.269-22.82 1.772-2.622.251-4.627.464-5.973.614a213.493 213.493 0 0 0-1.901.22l-.094.01-.028.004Z"></path>
												</svg>
											</span>
                        </mark>
                    </h2>
                </div>
                <div className="ld-fancy-heading relative flex text-center animation-element">
                    <p className="ld-fh-element inline-block relative mb-0/5em text-18 font-medium leading-22 text-black-70">
                        {subtitle}
                    </p>
                </div>
                <div className="w-full mt-20">
                    <div
                        className="ld-sf relative ld-sf--input-bordered ld-sf--button-solid ld-sf--size-sm ld-sf--round ld-sf--border-none ld-sf--input-shadow ld-sf--button-show ld-sf--button-inline animation-element">
                        <form className="ld_subscribe_form ld_sf_form relative action-form" method="post"
                              action="#">
                            <a href={actionLink} type={'submit'}
                                    className="inline-flex items-center justify-center m-0 rounded-2 relative text-13 font-medium bg-blue-900 text-white">
                                <span className="submit-text">{actionTitle}</span>
                            </a>
                        </form>
                        <div className="ld_sf_response"></div>
                    </div>
                </div>
            </div>
            <div className="w-560 flex flex-wrap gap-20 items-start justify-center mt-30 sm:w-full"
                // data-custom-animations="false"
                 data-ca-options='{"animationTarget": ".animation-element", "duration" : 850, "startDelay" : 800, "delay" : 100, "ease": "expo.out", "initValues": {"opacity" : 0, "scale" : 0.75} , "animations": {"opacity" : 1, "scale" : 1}}'>
                <div
                    className="ld-fancy-heading relative w-full max-w-full text-center animation-element">
                    <p className="ld-fh-element inline-block relative text-14 font-medium leading-22 text-black mb-0/5em">
                        {bottomTitle}
                    </p>
                </div>
                <div
                    className="lqd-imggrp-single block relative border-right border-black-10 flex-grow-1 flex-shrink-1 text-center sm:w-25percent animation-element">
                    <div
                        className="lqd-imggrp-img-container inline-flex relative items-center justify-center opacity-30">
                        <figure className="w-full relative">
                            <img className="sm:w-70percent" width="144" height="50"
                                 src={img1}
                                 alt="clients"/>
                        </figure>
                    </div>
                </div>
                <div
                    className="lqd-imggrp-single block relative border-right border-black-10 flex-grow-1 flex-shrink-1 text-center sm:w-25percent animation-element">
                    <div
                        className="lqd-imggrp-img-container inline-flex relative align-center justify-contentnter opacity-30">
                        <figure className="w-full relative">
                            <img className="sm:w-70percent" width="144" height="50"
                                 src={img2}
                                 alt="clients"/>
                        </figure>
                    </div>
                </div>
                <div
                    className="lqd-imggrp-single block relative border-black-10 flex-grow-1 flex-shrink-1 text-center sm:w-25percent animation-element">
                    <div
                        className="lqd-imggrp-img-container inline-flex relative items-center justify-center opacity-30">
                        <figure className="w-full relative">
                            <img className="sm:w-70percent" width="144" height="50"
                                 src={img3}
                                 alt="clients"/>
                        </figure>
                    </div>
                </div>

            </div>
        </div>
    </section>);
}

export default Offer;