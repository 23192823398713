import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

function Form({
                  tagTitle,
                  title,
                  subtitle
              }) {

    const domain = "https://api.campus88.ru";
    // const domain = "http://localhost:9091";
    const {studentId} = useParams();

    const [firstName, setFirstName] = useState("");
    const [university, setUniversity] = useState("");
    const [spec, setSpec] = useState("");
    const [year, setYear] = useState("");
    const [cv, setCv] = useState("");
    const [letter, setLetter] = useState("");
    const [didLoad, setDidLoad] = useState(false);

    const saveData = async (submit) => {
        const response = await fetch(domain + "/not-secure/api/v1/form/" + studentId + "?submit="+submit, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({
                university: university,
                spec: spec,
                year: year,
                cv: cv,
                letter:letter
            })
        });
        console.log(response)
    }

    useEffect(() => {
        if (didLoad == false) {
            return;
        }
        // call the function
        saveData("false")
            // make sure to catch any error
            .catch(console.error);

        console.log('didChange')
    }, [university, spec, year, cv, letter]);

    useEffect(() => {
        if (didLoad == false) {
            const fetchData = async () => {
                const response = await fetch(domain + "/not-secure/api/v1/form/" + studentId);
                const studentResponse = await response.json();
                const student = studentResponse.result;
                console.log(student)

                setFirstName(student.firstName);
                setUniversity(student.grant.university)
                setSpec(student.grant.spec)
                setYear(student.grant.year)
                setCv(student.grant.cv)
                setLetter(student.grant.letter)

                setTimeout(() => {
                    setDidLoad(true)
                }, 1000)
            }

            // call the function
            fetchData()
                // make sure to catch any error
                .catch(console.error);
        }
    })

    return (<section
            className="lqd-section compare flex flex-col items-center justify-center transition-all rounded-30 shadow-md bg-white bg-center bg-no-repeat py-40"
            id="compare" style={{"background-image": "url(./assets/images/demo/start-hub-x/bg/map.png)"}}
            data-custom-animations="false"
            data-ca-options='{"animationTarget": ".animation-element, .iconbox", "duration" : 850, "startDelay" : 300, "delay" : 75, "ease": "expo.out", "initValues": {"y": "40px", "scaleX" : 0.8, "scaleY" : 0.8, "opacity" : 0} , "animations": {"y": "0px", "scaleX" : 1, "scaleY" : 1, "opacity" : 1}}'>
            <div
                className="ld-fancy-heading relative flex self-center text-center bg-transparent rounded-100 animation-element"
                style={{"background-image": "linear-gradient(90deg, #E3C4E9 0%, #EAEDFB 100%)"}}>
                <h6 className="ld-fh-element inline-block relative py-5 px-15 m-0">
                    {tagTitle}
                </h6>
            </div>
            <div className="spacer w-full animation-element">
                <div className="w-full h-20"></div>
            </div>
            <div className="flex flex-row items-end justify-center gap-10">
                <div className="ld-fancy-heading relative flex text-center animation-element">
                    <h2 className="ld-fh-element inline-block relative ld-gradient-heading bg-transparent m-0 pb-0/2em"
                        style={{"background-image": "linear-gradient(180deg, #7F5BC4 0%, #60A3E6 100%)"}}>
                        {firstName}, заполни форму
                    </h2>
                </div>
                {/*<div className="ld-fancy-heading relative animation-element">*/}
                {/*                        <span*/}
                {/*                            className="ld-fh-element inline-block relative text-52 leading-1em mb-0/2em">🤘🏽</span>*/}
                {/*</div>*/}
            </div>
            <div className="ld-fancy-heading relative lg:text-center animation-element">
                <p className="ld-fh-element inline-block relative mb-0/5em text-17 leading-24 font-medium text-black">
                    {subtitle}
                </p>
            </div>
            <div className="spacer w-full animation-element">
                <div className="w-full h-20"></div>
            </div>
            <div
                className="w-full flex flex-wrap justify-evenly border-top border-black-10 px-10 pt-10 pb-0 grant-form-section">
                <div className={'grant-form-label'}>
                    ВУЗ/Колледж
                </div>
                <input
                    className={'grant-form-input'}
                    placeholder={'Введите учебное заведение'}
                    value={university}
                    onChange={(val) => setUniversity(val.target.value)}
                />

                <div className={'grant-form-label'}>
                    Направление/Специальность
                </div>
                <input
                    className={'grant-form-input'}
                    placeholder={'Специализация'}
                    value={spec}
                    onChange={(val) => setSpec(val.target.value)}
                />

                <div className={'grant-form-label'}>
                    Дата окончания обучения
                </div>
                <input className={'grant-form-input'} placeholder={'Достаточно написать просто год'}
                       value={year}
                       onChange={(val) => setYear(val.target.value)}
                />

                <div className={'grant-form-label'}>
                    Опишите Ваш опыт в IT
                </div>
                <textarea className={'grant-form-input'} placeholder={'В двух словах'} rows={5} value={cv}
                          onChange={(val) => setCv(val.target.value)}
                ></textarea>

                <div className={'grant-form-label'}>
                    Почему грант должны получить именно Вы?
                </div>
                <textarea className={'grant-form-input'} placeholder={'Напишите пару предложений. Для нас это важно'}
                          rows={5} value={letter}
                          onChange={(val) => setLetter(val.target.value)}
                ></textarea>


                <button className={'grant-form-button'}
                        onClick={() => {
                            saveData("true").catch(console.log);
                            alert("Спасибо, данные сохранены")
                        }}
                >
                    сохранить
                </button>

            </div>
            <div className="w-full flex items-center justify-center p-10">
                <div
                    className="ld-fancy-heading relative flex self-center border-1 border-black-10 rounded-10 animation-element">
                    <p className="ld-fh-element inline-block relative m-0 text-12 text-black py-10 px-15">
                        <span>Мы так же можем ответить на вопросы в телеграм</span>
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Form;