import './style.css';
import BlockTextRight from "../../../ui/components/blockTextRight";
import BlockTextLeft from "../../../ui/components/blockTextLeft";
import HowItWork, {IconMenu} from "../../../ui/components/howItWork";
import Compare, {colorFullListItem, greyListItem} from "../../../ui/components/compare";
import Faq, {AccordionItem} from "../../../ui/components/faq";
import ReadyToStart from "../../../ui/components/readyToStart";
import Offer from "../../../ui/components/offer";
import Prices from "../../../ui/components/prices";
import Disclaimer from "../../../ui/components/disclaimer";
import MainLayout from "../../layout/mainLayout";

import imgJava from './img/java.png'
import imgSpring from './img/spring.png'
import imgMongo from './img/mongo.png'
import imgContent from './img/content.png'
import imgUnicorn from './img/unicorn.png'

function MenuItemList() {
    return (<>
        <li>
            <a href="#what-can-i-get">
                Что дает курс?
            </a>
        </li>

        <li>
            <a href="#how-it-work">
                Программа курса
            </a>
        </li>
        <li>
            <a href="#prices">
                Цены
            </a>
        </li>
        <li>
            <a href="/grant">
                Гранты на обучение
                <span className="link-badge text-badge">New</span>
            </a>
        </li>
    </>);
}

function JavaPage() {

    return (
        <MainLayout menuItemList={MenuItemList()}>
            <Offer
                titleFirstLine={'Java'}
                titleSecondLine={' разработчик'}
                subtitle={'Вы получите базовые знания алгоритмы, на практике освоите язык Java и сможете создавать универсальные программы для большинства современных платформ.'}
                actionTitle={'Записаться на курс'}
                bottomTitle={'Важные технологии для успешного старта'}
                actionLink={'#prices'}
                img1={imgJava}
                img2={imgSpring}
                img3={imgMongo}
            ></Offer>
            <Disclaimer
                title={'Курс запускается потоками'}
                description={<>
                    При запуске нового потока мы обновляем курс,
                    что бы вы получили навыки, которые прямо сейчас нужны в реальной работе.

                    Последняя дата обновления - <span className={'disclaimer-text-important'}>сентябрь 2023 года.</span>
                </>}/>
            <div
                className="module-sections relative flex flex-col items-center gap-60 rounded-top-100 pt-100 pb-50 px-10 bg-top-center bg-cover bg-no-repeat transition-all shadow-top lg:border-radius-0 lg:py-0"
                style={{"background-image": "url(./assets/images/demo/start-hub-x/bg/page-bg.svg);"}}
            >
                <BlockTextLeft
                    tagTitle={'Java разработчик • Junior'}
                    title={'Что дает профессия?'}
                    description={<>
                        <b>Работу на самом востребованном языке.</b> Программы на Java используют более 3 миллиардов
                        устройств в
                        мире.
                    </>}
                    tag1={'Java Core'}
                    tag2={'Mongo DB'}
                    tag3={'NoSQL'}
                />
                <HowItWork
                    tagTitle={'Java разработчик • Junior'}
                    title={'Содержание курса'}
                    imgContent={imgContent}
                    wayItems={<>
                        {IconMenu("icon-num-1", "Java Base", "Базовые типы и структуры данных, переменные. Условные операторы, работа с функциями.", false)}
                        {IconMenu("icon-num-2", "Maven", "Основы работы с Maven. Сборка проекта", false)}
                        {IconMenu("icon-num-3", "HTTP", "Знакомство с HTTP", false)}
                        {IconMenu("icon-num-4", "REST API", "Работаем с запросами в Java. REST API, работа с запросами", false)}
                        {IconMenu("icon-num-5", "Spring", "Знакомство с Spring. Делаем первое web приложение", false)}
                        {IconMenu("icon-num-6", "Базы данных", "Знакомство с современными базами данных", false)}
                        {IconMenu("icon-num-7", "Тестирование", "Добавление Unit тестов в наше приложение", false)}
                        {IconMenu("icon-num-8", "Swagger", "Документация REST API. Swagger", false)}
                        {IconMenu("icon-num-9", "Security", "Защита Web приложений. Spring Security", false)}
                        {IconMenu("icon-num-10", "Проектная работа / стажировка", "Работаем над проектами", true)}
                    </>}
                />
                <Compare
                    tagTitle={'Java разработчик • Junior'}
                    title={'Опыт после курса'}
                    subtitle={'Какие скиллы вы можете указать в своем резюме'}
                    colTitle1={'Теоретические навыки'}
                    colTitle2={'Практические навыки'}
                    colItems1={<>
                        {colorFullListItem("Проект API для аналитики")}
                        {colorFullListItem("Проект API для доставки")}
                        {colorFullListItem('Проект "Маленькие Люди"')}
                        {colorFullListItem('Стажировка в "Блинбери"')}
                        {colorFullListItem('Стажировка в "Сушивесла"')}
                        {colorFullListItem('Стажировка в "FoodTech Lab"')}
                    </>}
                    colItems2={<>
                        {greyListItem("Разработка Web приложений")}
                        {greyListItem("Написание Unit тестов")}
                        {greyListItem("Работа с SQL базами")}
                        {greyListItem("Работа с NoSQL базами")}
                        {greyListItem("Работа с Git")}
                        {greyListItem("Работа с Spring Framework")}
                    </>}
                />
                <BlockTextRight
                    idAnchor={'hr'}
                    tagTitle={' HR • UnicornSearch.pro'}
                    title={'Помощь в поиске работы'}
                    description={'Мы знаем, насколько сложно найти первую работу. Совместо с агенством UnicornSearch.pro мы помогаем в составлении резюме и поиске первого места работы.'}
                    img={imgUnicorn}
                />
                <Prices></Prices>
                {/*<Features></Features>*/}
                <ReadyToStart
                    idAnchor={'go-to-grant'}
                    title={'Гранты на обучение'}
                    subtitle={'Ты можешь подать заявление на грант, который дает возможность получить образование бесплатно!'}
                    actionTitle={'Как получить грант?'}
                    actionLink={"/grant"}
                />
                <Faq
                    tagTitle={'FAQ • Поддержка'}
                    title={'Часто задаваемые вопросы?'}
                    items={<>
                        {AccordionItem(
                            "Я никогда не занимался Java-разработкой. У меня получится?",
                            "У нас нет цели сформировать завышенные ожидания. Мы открыто говорим, что есть студенты, которые не могут осилить программу. Таких примерно 20%. Для того, что бы все получилось — просто уделяйте больше внимания практике и читайте дополнительную литературу. "
                        )}
                        {AccordionItem(
                            "Какой график обучения на платформе?",
                            "Вы можете изучать материалы курса в удобном вам режиме, совмещать обучение на платформе с работой и личной жизнью. Более того, все видео будут доступны и по окончании курса, так что вы сможете освежить свои знания в любой момент."
                        )}
                        {AccordionItem(
                            "Получится ли совмещать обучение с работой?",
                            "Вы можете изучать материалы курса в удобном вам режиме, совмещать обучение на платформе с работой и личной жизнью. Более того, все видео будут доступны и по окончании курса, так что вы сможете освежить свои знания в любой момент."
                        )}
                        {AccordionItem(
                            "Какая длительность у курса?",
                            "Продолжительность основного курса — 6 месяцев. За это время вы в среднем темпе сможете изучить  видеоматериала и выполнить  практические работы, чтобы отточить навыки."
                        )}
                        {AccordionItem(
                            "Сколько часов в неделю мне нужно будет уделять курсу?",
                            "Зависит от того, насколько быстро вы хотите освоить профессию. Чтобы пройти курс за полгода и трудоустроиться к этому времени, нужно заниматься по 2 часа в день. Но совсем необязательно следовать жёсткому графику — учиться можно когда вам удобно, в комфортном для вас темпе."
                        )}
                        {AccordionItem(
                            "Кто будет мне помогать в обучении на платформе?",
                            'Если вы выберите "Расширенный план" у вас будут проверяющие эксперты и куратор в Telegram-чате курса. Они прокомментируют практические работы, дадут полезные советы и ответят на вопросы.'
                        )}
                        {AccordionItem(
                            "Действуют ли какие-нибудь программы рассрочки?",
                            "Да, вы можете купить курс в рассрочку — и спланировать свой бюджет, разбив всю сумму на мелкие ежемесячные платежи."
                        )}
                        {AccordionItem(
                            "Объясняем термины, которые используются на этом сайте",
                            <>
                                Код — инструкции, которые пишут на языке программирования. Такие инструкции можно
                                «перевести» в специальный формат — набор нулей и единиц, который будет понятен
                                компьютеру.
                                <br/><br/>
                                Хранилище данных — компьютерная система для хранения информации. Ближайшая аналогия —
                                библиотека, в которой упорядоченно хранятся разные документы: книги, журналы, газеты. Мы
                                можем выбрать и получить нужные нам на время данные.
                                <br/><br/>
                                Оптимизация приложений — улучшение производительности приложения, например, сокращение
                                времени поиска, увеличение количества обслуживаемых клиентов. При этом результат,
                                который мы ожидаем от приложения, не меняется. Например, мы нашли более короткий путь в
                                булочную, и вместо 15 минут на дорогу теперь тратим 10. Это значит, что мы
                                оптимизировали маршрут по времени.
                                <br/><br/>
                                Поисковый движок — программа, которая позволяет искать информацию среди большого объёма
                                данных. Например, Яндекс ищет сайты, интернет-магазины помогают отыскать подходящие
                                товары среди десятков тысяч наименований. Во всех этих примерах «под капотом» работает
                                поисковой движок.
                                <br/><br/>
                                Техническое задание, или ТЗ — документ, в котором содержатся все требования к программе.
                                Написан на понятном языке, содержит диаграммы, схемы, таблицы, изображения. Когда
                                разработчики сдают программу заказчику, тот оценивает результат по требованиям из ТЗ.
                                <br/><br/>
                                Переменные — области памяти, которые использует программа для хранения данных во время
                                своей работы. Переменные можно сравнить с ящиками в камере хранения. Как и у ящиков, у
                                каждой переменной есть номер — идентификатор. По нему можно обратиться, чтобы
                                использовать данные, которые хранятся внутри.
                                <br/><br/>
                                Пилотные работы — работы для проверки гипотез. Перед тем, как делать что-то масштабное,
                                желательно проверить: будет ли это вообще работать и надо ли кому? Например, в
                                производстве сериалов часто снимают пилотную серию — её показывают фокус-группе и
                                определяют, стоить ли работать дальше и попробовать что-то новое. Так же и с
                                программами.
                                <br/><br/>
                                Принципы объектно-ориентированного программирования (ООП) — идеи и правила, которые
                                определяют, как писать программу. При объектно-ориентированном подходе приложение
                                состоит из отдельных сущностей — объектов. Они имеют свои свойства, методы и могут
                                взаимодействовать между собой или наследоваться. Например, объект «Кошка» может иметь
                                свойства, унаследованные от объекта «Животное». Основные принципы ООП:
                                <br/><br/>
                                Абстракция — объект должен содержать только важные для него свойства.
                                Инкапсуляция — вся логика для работы с объектом должна быть прописана внутри объекта.
                                Наследование — мы уже упомянули про этот принцип выше. Объекты могут наследовать
                                свойства и методы и своих родителей. «Человек» → «Женщина», «Автомобиль» →
                                «Внедорожник», «Рыба» → «Щука» и так далее.
                                Полиморфизм — одни и те же методы могут выполнять разные задачи. Например, метод
                                «поздороваться» вернёт приветствие на французском или русском языке, в зависимости от
                                того, какую страну проживания выбрал пользователь.
                                <br/><br/>
                                Фреймворк Spring — программная платформа, которая облегчает разработку веб-приложений на
                                Java. Фреймворк берёт на себя все рутинные задачи, позволяет разработчику
                                сфокусироваться только на бизнес-задачах.
                                <br/><br/>
                                Написание запросов — создание команд для работы с базами данных для чтения, добавления,
                                изменения и удаления данных базы данных.
                                <br/><br/>
                                Базы данных — программы, которые позволяют хранить различные данные и работать с ними:
                                читать, добавлять новые, изменять и удалять существующие.
                                <br/><br/>
                                Реляционные базы данных — базы данных, в которых данные хранятся в виде таблиц, между
                                которыми можно установить связь. К таким базам данных относятся MySQL, PostgreSQL,
                                Oracle Database, YDB и множество других.
                                <br/><br/>
                                Система контроля версий Git — система, с помощью которой можно удобно хранить и
                                отслеживать изменения в коде. Git помогает командам разработчиков работать синхронно —
                                видно, кто что делает, а если что-то пошло не так, то в любой момент можно откатиться
                                назад. Для любого разработчика знать основы GIt — обязательное требование.
                            </>
                        )}
                    </>}
                />
                {/*<IconsSection></IconsSection>*/}
                {/*<RealStories></RealStories>*/}
            </div>
        </MainLayout>
    );
}

export default JavaPage;
