import {BrowserRouter, Route, Routes} from "react-router-dom";
import JavaPage from "./ui/pages/java";
import React from "react";
import GrantPage from "./ui/pages/grant";
import GrantFormPage from "./ui/pages/grant_form";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="grant-form/:studentId" element={<GrantFormPage/>} />
                <Route path="grant" element={<GrantPage/>} />
                <Route path="" element={<JavaPage />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
