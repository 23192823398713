import './style.css';

function Header({menuItemList}) {
    return (<header id="site-header" className="main-header main-header-overlay sticky-header-noshadow header-component"
                    data-sticky-header="true" data-sticky-values-measured="false">


        <div className="w-full flex items-stretch justify-center px-10 md:hidden">
            <div className="w-960 flex items-stretch justify-center py-10">
                <div
                    className="w-full statick flex items-center justify-between px-30 bg-white rounded-10 shadow-blue module-header-bottom">
                    <div className="module-logo flex navbar-brand-plain">
                        <a className="navbar-brand flex p-0 relative" href="/"
                           rel="home">
									<span className="navbar-brand-inner post-rel header-component-logo-lg">
                                        Campus88
									</span>
                        </a>
                    </div>
                    <div className="module-primary-nav flex">
                        <div className="navbar-collapse inline-flex p-0 lqd-submenu-default-style"
                             aria-expanded="false" role="navigation">
                            <ul className="main-nav flex reset-ul inline-ul lqd-menu-counter-right lqd-menu-items-inline main-nav-hover-fill link-13 link-black link-medium"
                                data-submenu-options='{"toggleType": "fade", "handler": "mouse-in-out"}'
                                data-localscroll="true"
                                data-localscroll-options='{"itemsSelector":"> li > a", "trackWindowScroll": true, "includeParentAsOffset": true}'>
                                {menuItemList}

                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div className="lqd-mobile-sec relative header-component-mobile">
            <div className="lqd-mobile-sec-inner navbar-header flex items-stretch w-full header-component-mobile-block">
                <div className="lqd-mobile-modules-container empty"></div>
                <button type="button"
                        className="navbar-toggle collapsed nav-trigger style-mobile flex relative items-center justify-end border-none bg-transparent p-0"
                        data-ld-toggle="true" data-bs-toggle="collapse" data-bs-target="#lqd-mobile-sec-nav"
                        aria-expanded="false"
                        data-bs-toggle-options='{"changeClassnames":  {"html": "mobile-nav-activated"} }'>
                    <span className="sr-only">Menu</span>
                    <span className="bars inline-block relative z-1">
								<span className="bars-inner flex flex-col w-full h-full">
									<span className="bar inline-block"></span>
									<span className="bar inline-block"></span>
									<span className="bar inline-block"></span>
								</span>
							</span>
                </button>
                <a className="navbar-brand flex relative header-component-logo" href="/">
							<span className="navbar-brand-inner header-component-logo-text">
								{/*<img className="logo-default" src="./assets/images/demo/start-hub-x/logo/logo.svg"*/}
                                {/*     alt="StartHub X"/>*/}
                                Campus88
							</span>
                </a>
            </div>
            <div className="lqd-mobile-sec-nav w-full absolute z-10 header-component-mobile-menu">
                <div className="mobile-navbar-collapse navbar-collapse collapse w-full bg-white header-component-mobile-menu-section"
                     id="lqd-mobile-sec-nav" aria-expanded="false" role="navigation">
                    <ul id="mobile-primary-nav"
                        className="reset-ul lqd-mobile-main-nav main-nav text-black">
                        {menuItemList}
                    </ul>
                </div>
            </div>
        </div>
    </header>);
}

export default Header;