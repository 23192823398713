import './block-left.css'
import imgPrint from './img/print-hello.png';

function BlockTextLeft({
                           tagTitle,
                           title,
                           description,

                           tag1, tag2, tag3
                       }) {
    return (<section
        className="lqd-section manage-data flex flex-grow-0 flex-shrink-1 items-center transition-all rounded-30 shadow-md bg-white py-40 px-60 lg:flex-col-reverse"
        id="what-can-i-get" data-custom-animations="false"
        data-ca-options='{"animationTarget": ".animation-element", "duration" : 850, "startDelay" : 300, "delay" : 100, "ease": "expo.out", "initValues": {"y": "30px", "scaleX" : 0.8, "scaleY" : 0.8, "opacity" : 0} , "animations": {"y": "0px", "scaleX" : 1, "scaleY" : 1, "opacity" : 1}}'>
        <div
            className="w-50percent relative flex flex-col p-10 lg:w-full lg:text-center bg-top-left bg-cover"
            style={{"background-image": "url(./assets/images/demo/start-hub-x/manage-data/lines.jpg)"}}>
            <div
                className="ld-fancy-heading relative flex self-start bg-transparent rounded-100 lg:self-center animation-element"
                style={{"background-image": "linear-gradient(90deg, #E3C4E9 0%, #EAEDFB 100%)"}}>
                <h6 className="ld-fh-element inline-block relative py-5 px-15 m-0">{tagTitle}</h6>
            </div>
            <div className="spacer w-full animation-element">
                <div className="w-full h-30"></div>
            </div>
            <div
                className="ld-fancy-heading relative flex text-start mr-30percent lg:mr-0 lg:self-center lg:text-center animation-element">
                <h2 className="ld-fh-element inline-block relative ld-gradient-heading bg-transparent mb-0/2em pb-0/2em"
                    style={{"background-image": "linear-gradient(180deg, #7F5BC4 0%, #60A3E6 100%)"}}>
                    {title}</h2>
            </div>
            <div className="max-w-full absolute top-55 module-emoji lg:hidden">
                <div className=" ld-fancy-heading relative animation-element">
                    <span className="ld-fh-element inline-block relative m-0 text-52">🤩 </span>
                </div>
            </div>
            <div
                className="ld-fancy-heading relative mr-25percent lg:m-0 lg:self-center animation-element">
                <p className="ld-fh-element inline-block relative mb-0/5em text-17 font-medium leading-22 text-black-70">
                    {description}
                </p>
            </div>
            <div className="w-full flex flex-row flex-wrap mt-40 gap-15 lg:justify-center">
                <div className="ld-fancy-heading relative flex self-start animation-element">
                                            <span
                                                className="ld-fh-element inline-block relative text-12 font-medium m-0 py-5 px-15 text-black bg-gray-200 rounded-6">
                                                {tag1}
                                            </span>
                </div>
                <div className="ld-fancy-heading relative flex self-start animation-element">
                                            <span
                                                className="ld-fh-element inline-block relative text-12 font-medium m-0 py-5 px-15 text-black bg-gray-200 rounded-6">
                                                {tag2}
                                            </span>
                </div>
                <div className="ld-fancy-heading relative flex self-start animation-element">
                                            <span
                                                className="ld-fh-element inline-block relative text-12 font-medium m-0 py-5 px-15 text-black bg-gray-200 rounded-6">
                                                {tag3}
                                            </span>
                </div>
            </div>
        </div>
        <div
            className="relative flex justify-center items-center rounded-20 overflow-auto module-video animation-element">
            <div
                className="background-video-container w-full h-full absolute flex justify-center items-center top-0 left-0 overflow-hidden rounded-20 z-0 transition-opacity pointer-events-none">
                <img src={imgPrint} height={370}
                     className={'background-video-hosted html5-video max-w-full absolute rounded-20'}/>
            </div>
        </div>
    </section>);
}

export default BlockTextLeft;