function BlockTextRight({
                            idAnchor,
                            tagTitle,
                            title,
                            description,
                            img
                        }) {
    return (<section
        className="lqd-section commit flex flex-row flex-grow-0 flex-shrink-1 items-center transition-all rounded-30 shadow-md bg-white py-40 px-30 lg:flex-col"
        id={idAnchor} data-custom-animations="false"
        data-ca-options='{" addChildTimelines": false, "animationTarget": ".animation-element", "duration" : 850, "startDelay" : 300, "delay" : 75, "ease": "expo.out", "initValues": {"y": "30px", "scaleX" : 0.8, "scaleY" : 0.8, "opacity" : 0} , "animations": {"y": "0px", "scaleX" : 1, "scaleY" : 1, "opacity" : 1}}'>
        <div
            className="w-80percent flex justify-center rounded-inherit lg:w-full animation-element">
            <div className=" block relative" data-shadow-style="1"
                 data-inview="true" data-animate-shadow="false">
                <div
                    className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative shadow-md">
                        <img className="rounded-30" width="820" height="756"
                             src={img}
                             alt="commit"/>
                    </figure>
                </div>
            </div>
        </div>
        <div
            className="flex flex-col p-10 mr-10 ml-90 transition-bg text-start lg:text-center lg:m-0 animation-element">
            <div
                className="ld-fancy-heading relative flex self-start bg-transparent rounded-100 lg:self-center"
                style={{"background-image": "linear-gradient(90deg, #E3C4E9 0%, #EAEDFB 100%)"}}>
                <h6 className="ld-fh-element inline-block relative py-5 px-15 m-0">
                    {tagTitle}
                </h6>
            </div>
            <div className="spacer w-full">
                <div className="w-full h-20"></div>
            </div>
            <div className="ld-fancy-heading relative text-start lg:text-center">
                <h2 className="ld-fh-element inline-block relative ld-gradient-heading bg-transparent mb-0/2em pb-0/2em"
                    style={{"background-image": "linear-gradient(180deg, #7F5BC4 0%, #60A3E6 100%)"}}>
                    {title}
                </h2>
            </div>
            <div className="ld-fancy-heading relative lg:text-center">
                <p className="ld-fh-element inline-block relative mb-0/5em text-17 font-medium leading-22 text-black-70">
                    {description}
                </p>
            </div>
        </div>
    </section>);
}

export default BlockTextRight;