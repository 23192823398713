function ReadyToStart({
	title,
	subtitle,
	actionTitle,
	idAnchor,
	actionLink
					  }){
    return (    <section
            className="lqd-section cta flex flex-wrap items-stretch justify-between flex-grow-1 transition-all rounded-30 bg-transparent bg-no-repeat bg-center px-60 sm:items-end sm:flex-col"
            id={idAnchor} data-custom-animations="false"
            data-ca-options='{"animationTarget": ".animation-element, .btn", "duration" : 800, "startDelay" : 300, "delay" : 75, "ease": "expo.out", "initValues": {"y": "40px", "opacity" : 0} , "animations": {"y": "0px", "opacity" : 1}}'>
            <div
                className="w-40percent flex flex-col justify-center transition-bg p-10 lg:w-50percent sm:w-full">
                <div className="ld-fancy-heading relative animation-element">
                    <h2 className="ld-fh-element inline-block relative mb-0/2em text-white">
						{title}
                    </h2>
                </div>
                <div className="ld-fancy-heading relative animation-element">
                    <p className="ld-fh-element inline-block relative text-16 font-medium leading-22 text-white-70 mb-0/5em">
						{subtitle}
                    </p>
                </div>
                <div className="flex flex-row mt-20 mb-50 self-stretch transition-bg module-btn">
                    <a href={actionLink}
                       className="btn btn-solid btn-icon-left text-13 font-medium bg-transparent border-3 text-white rounded-full hover:bg-white module-btn-md"
                       target="_blank">
                        <span data-text="App Store" className="btn-txt">
							{actionTitle}
                        </span>
                    </a>

                </div>
            </div>
            <div className="w-40percent flex self-end p-10 sm:w-full">
                <div className="ld-fancy-heading relative animation-element">
                    <h2 className="ld-fh-element inline-block relative txt-rotator-list text-44 font-bold m-0"
                        data-text-rotator="true"
                        data-text-rotator-options='{"animationType": "list"}'>
										<span className="txt-rotate-keywords text-black-20">
											<span className="txt-rotate-keywords-inner h-full block">
												<span className="txt-rotate-keyword">
													<span>Java Разработка 🔋</span>
												</span>
												<span className="txt-rotate-keyword">
													<span>Spring 💡</span>
												</span>
												<span className="txt-rotate-keyword">
													<span>MongoDb ✏️</span>
												</span>
												<span className="txt-rotate-keyword">
													<span>PostgreSQL</span>
												</span>
												<span className="txt-rotate-keyword">
													<span>Lombok 📝</span>
												</span>
												<span className="txt-rotate-keyword">
													<span>Первая работа ⚡</span>
												</span>
												<span className="txt-rotate-keyword">
													<span>Jira</span>
												</span>
												<span className="txt-rotate-keyword">
													<span>Git 👨‍💼</span>
												</span>
											</span>
										</span>
                    </h2>
                </div>
            </div>
        </section>
    );
}

export default ReadyToStart;