import './disclaimer.css'

function Disclaimer({
    title,
    description
                    }) {
    return (<div className={'disclaimer'} id={'disclaimer'}>
        <div className={'disclaimer-text'}>
            <h3 className={'disclaimer-text-title'}>{title}</h3><br/>
            {description}
        </div>
    </div>)
}

export default Disclaimer