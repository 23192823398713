import './footer.css'

function Footer(){
    return (<footer id="site-footer" className="main-footer relative overflow-hidden transition-all bg-slate-100">
        <div
            className="w-full max-w-full absolute z-0 -bottom-70percent ltr-left-0 module-lottie rotate-180 sm:hidden">
            <div id="lqd-lottie-footer" className="lqd-lottie h-full"></div>
        </div>
        <section className="lqd-section flex flex-wrap flex-grow-1 flex-shrink-1 mx-auto py-20 lg:py-0">
            <div className="w-30percent flex items-center pt-20 pb-35 sm:w-full sm:order-first sm:pt-0">
                <div className="lqd-imggrp-single block relative w-90">
                    <div
                        className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                        <figure className="w-full relative">
                            Campus88
                        </figure>
                    </div>
                </div>
            </div>
            <div className="w-70percent flex items-center  pt-20 pb-35 sm:w-full sm:pt-0 main-footer-menu">
                <div className="lqd-fancy-menu lqd-custom-menu relative lqd-menu-td-none">
                    <ul className="reset-ul inline-ul link-14">
                        <li className="mr-40">
                            <a className="text-slate-600 hover:text-primary" href="/assets/privacy.pdf"  target='_blank'>
                                            <span
                                                className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label text-blue-500"></span>
                                <span>Политика конфиденциальности</span>

                            </a>
                        </li>
                        <li>
                            <a className="text-slate-600 hover:text-primary" href="/assets/oferta.pdf" target='_blank'>

                                <span>Оферта</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="w-full flex items-center divider py-5">
                <span className="w-full border-top border-black-20 pb-10"></span>
            </div>
            <div className="w-50percent flex items-center pt-20 pb-35 module-text sm:w-full">
                <div className="ld-fancy-heading relative">
                    <p className="ld-fh-element inline-block relative mb-0/5em text-12 leading-20 text-black-60">
                        © campus88, 2023
                    </p>
                </div>
            </div>

        </section>
    </footer>);
}

export default Footer;