import './faq.css'

export function AccordionItem(title, description){
    const min = 100000,max = 900000
    const randomId = "ran" + (Math.random() * (max - min) + min);
    const collapseId = "col-" + randomId
    return (
        <div className="accordion-item panel">
            <div className="accordion-heading" role="tab" id={randomId}>
                <h4 className="accordion-title">
                    <a className="collapsed text-20 font-medium leading-25 text-black border-bottom border-black-10"
                       role="button" data-bs-toggle="collapse"
                       data-bs-parent="#accordion-faq" href={"#"+collapseId}
                       aria-expanded="false" aria-controls={collapseId}>
                        <span className="accordion-title-txt">{title}</span>
                        <span
                            className="accordion-expander text-16 w-30 h-30 items-center justify-center flex-shrink-0 p-0 border-1 border-black-10 rounded-full">
														<i className="lqd-icn-ess icon-ion-ios-add"></i>
														<i className="lqd-icn-ess icon-ion-ios-remove"></i>
													</span>
                    </a>
                </h4>
            </div>
            <div id={collapseId} className="accordion-collapse collapse"
                 role="tabpanel" aria-labelledby={randomId}
                 data-bs-parent="#accordion-faq">
                <div className="text-16 pr-2em pb-2em text-slate-600">
                    <p className="m-0">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    );
}

function Faq({
    tagTitle,
    title,
    items
             }){
    return (
            <section
                className="lqd-section faq flex flex-col items-center justify-center transition-all rounded-30 shadow-md bg-white py-50 pr-120 pl-120"
                id="faq" data-custom-animations="false"
                data-ca-options='{"animationTarget": ".animation-element, .accordion, figure", "duration" : 850, "startDelay" : 300, "delay" : 75, "ease": "expo.out", "initValues": {"y": "40px", "scaleX" : 0.8, "scaleY" : 0.8, "opacity" : 0} , "animations": {"y": "0px", "scaleX" : 1, "scaleY" : 1, "opacity" : 1}}'>
                <div
                    className="ld-fancy-heading relative flex self-center text-center bg-transparent rounded-100 animation-element"
                    style={{"background-image": "linear-gradient(90deg, #E3C4E9 0%, #EAEDFB 100%)"}}>
                    <h6 className="ld-fh-element inline-block relative py-5 px-15 m-0">
                        {tagTitle}</h6>
                </div>
                <div className="spacer w-full animation-element">
                    <div className="w-full h-20"></div>
                </div>
                <div className="max-w-full absolute top-70 module-avatar faq-arrow">
                    <div className="lqd-imggrp-single block relative" data-float="ease">
                        <div
                            className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                            <figure className="w-full relative">
                                <img className="w-65 faq-arrow-img" width="62"
                                     src="/assets/images/demo/start-hub-x/features/Arrow-1.svg"
                                     alt="avatar"/>
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="ld-fancy-heading relative flex text-center animation-element">
                    <h2 className="ld-fh-element inline-block relative ld-gradient-heading bg-transparent m-0 pb-0/2em"
                        style={{"background-image": "linear-gradient(180deg, #7F5BC4 0%, #60A3E6 100%)"}}>
                        {title}</h2>
                </div>
                <div className="spacer w-full animation-element">
                    <div className="w-full h-60"></div>
                </div>
                <div className="w-full max-w-full">
                    <div className="accordion accordion-lg" id="accordion-faq" role="tablist"
                         aria-multiselectable="true">
                        {items}



                    </div>
                </div>
                <div className="w-full flex items-center justify-center p-10 mt-40 sm:p-0">
                    <div
                        className="ld-fancy-heading relative flex self-center border-1 border-black-10 rounded-10 animation-element">
                        <p className="ld-fh-element inline-block relative m-0 text-12 text-black py-10 px-15">
                            <span>Мы так же можем ответить на вопросы в телеграм</span>
                        </p>
                    </div>
                </div>
            </section>

        );
}

export default Faq;