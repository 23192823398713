import './style.css';
import BlockTextRight from "../../../ui/components/blockTextRight";
import HowItWork, {IconMenu} from "../../../ui/components/howItWork";
import Compare, {colorFullListItem, greyListItem} from "../../../ui/components/compare";
import Faq, {AccordionItem} from "../../../ui/components/faq";
import ReadyToStart from "../../../ui/components/readyToStart";
import Offer from "../../../ui/components/offer";
import Disclaimer from "../../../ui/components/disclaimer";
import MainLayout from "../../layout/mainLayout";

import imgBB from './img/blinberry.png'
import imgFTL from './img/foodtechlab.png'
import imgSV from './img/sushivesla.png'
import imgRostislavAvatar from './img/rostislav-avatar.png'
import imgSteps from './img/grant-steps.png'

function MenuItemList() {
    return (<>
        <li>
            <a href="#letter">
                Открытое письмо
            </a>
        </li>

        <li>
            <a href="#how-it-work">
                Условия получения гранта
            </a>
        </li>
        <li>
            <a href="#grant">
                Подать заявку
            </a>
        </li>
        <li>
            <a href="/">
                Курс Java-разработчик
                <span className="link-badge text-badge">New</span>
            </a>
        </li>
    </>);
}

function GrantPage() {

    return (
        <MainLayout menuItemList={MenuItemList()}>
            <Offer
                titleFirstLine={'Гранты'}
                titleSecondLine={' на обучение'}
                subtitle={'Мы создали фонд из которого финансируется обучение студентов. Мы считаем, что образование должно быть доступным и у каждого должен быть шанс на обучение'}
                actionTitle={'Как получить грант?'}
                actionLink={'#grant'}
                bottomTitle={'При поддержке компаний'}
                img1={imgBB}
                img2={imgFTL}
                img3={imgSV}
            ></Offer>
            <Disclaimer
                title={'Курс запускается потоками'}
                description={<>
                    При запуске нового потока мы обновляем курс,
                    что бы вы получили навыки, которые прямо сейчас нужны в реальной работе.

                    Последняя дата обновления - <span className={'disclaimer-text-important'}>сентябрь 2023 года.</span>
                </>}/>
            <div
                className="module-sections relative flex flex-col items-center gap-60 rounded-top-100 pt-100 pb-50 px-10 bg-top-center bg-cover bg-no-repeat transition-all shadow-top lg:border-radius-0 lg:py-0"
                style={{"background-image": "url(./assets/images/demo/start-hub-x/bg/page-bg.svg);"}}
            >
                <BlockTextRight
                    tagTitle={'Ростислав, когда?'}
                    title={'Открытое письмо'}
                    idAnchor={'letter'}
                    description={<div style={{'text-align': 'left'}}>
                        Друзья, привет!
                        <br/>
                        <br/>

                        Хочу поделиться некоторыми мыслями относительно того, почему образование на Campus88 являются
                        платными, и
                        какие гранты для обучения есть. <span style={{background: 'yellow'}}>
                        Для меня важно, чтобы вы понимали принципы и ценности,
                        которые лежат в основе моей образовательной деятельности.</span>
                        <br/>
                        <br/>
                        Любое образование на самом деле платное. Образование, всегда связано с финансовыми
                        затратами. Даже если студенты не оплачивают учебу напрямую, кто-то все равно платит за это.
                        За каждым образованием стоят ресурсы и усилия, которые кем-то финансируются.
                        <br/>
                        <br/>
                        Поэтому важно понимать, кто заказчик образовательной системы. Обычно это или государство или
                        бизнес (предприниматели). Обе системы образования имеют плюсы и минусы.
                        <br/>
                        <br/>
                        Культура грантов в образовательной системе, будь то университет или образовательный курс,
                        является важной частью образования (к примеру в MIT, CalTech или HUST). <span style={{background: 'yellow'}}>
                        Гранты способствуют разнообразию и инклюзивности,</span> обеспечивают доступность образования и научных исследований, и поддерживают развитие новых идей
                        и знаний.
                        <br/>
                        <br/>
                        Я не могу повлиять на образование в Университетах, но я могу его дополнить. Я стремлюсь
                        поддержать студентов и дать им шанс получить максимально практический опыт.
                        <br/>
                        <br/>
                        Мои курсы платные, и я считаю это правильным.
                        Но кроме этого, я принял решение предоставлять гранты и полностью оплачивать обучение,
                        чтобы сделать образование более доступным. Это является частью моей миссии поддерживать IT индустрию и талантливых студентов. В
                        конце концов, когда-то шанс дали мне и я смог получить опыт и теперь хочу этот опыт передать
                        другим.
                        <br/>
                        <br/>

                        С Уважением,<br/>
                        Ростислав
                    </div>}
                    img={imgRostislavAvatar}
                />

                <HowItWork
                    tagTitle={'Что делаем?'}
                    title={'Этапы получения гранта'}
                    imgContent={imgSteps}
                    wayItems={<>
                        {IconMenu("icon-num-1", "Написать в телеграм бот", "Начните регистрацию в телеграм боте. Всю информацию можно будет получить там", false)}
                        {IconMenu("icon-num-2", "Заполнить свой профиль", "Каждого студента мы будем оценивать в отдельности", false)}
                        {IconMenu("icon-num-3", "Пригласить друзей", "Кроме информации о себе, на выдачу гранта влияет участие в жизни Campus'а", false)}
                        {IconMenu("icon-num-4", "Решение по гранту", "Мы планируем объявить результаты 18 октября", true)}
                    </>}
                />
                <Compare
                    tagTitle={'Java разработчик • Junior'}
                    title={'Критерии'}
                    subtitle={<>На какие критерии мы будем обращать внимание и что поможет получить грант</>}
                    colTitle1={'Минимальные условия'}
                    colTitle2={'Приоритетные пункты'}
                    colItems1={<>
                        {colorFullListItem("Базовые знание в IT")}
                        {colorFullListItem("Обучение на IT специальности")}
                        {colorFullListItem('Количество приглашенных друзей')}
                    </>}
                    colItems2={<>
                        {greyListItem("Нужно быть студентом")}
                        {greyListItem("Корректно заполненные данные")}
                        {greyListItem("Сопроводительное письмо")}
                    </>}
                />

                <Faq
                    tagTitle={'FAQ • Поддержка'}
                    title={'Часто задаваемые вопросы?'}
                    items={<>
                        {AccordionItem(
                            "Если я получу грант, нужно ли мне будет что-либо оплачивать?",
                            "Нет, все финансовое обеспечение берут на себя партнеры Campus'а"
                        )}
                        {AccordionItem(
                            "Почему бесплатно? Где подвох?",
                            "Никакого подвоха нет и все прозрачно. Более подробно про цели и миссию грантов написано в открытом письме"
                        )}
                        {AccordionItem(
                            "Какие обязательства и условия связаны с получением этого гранта?",
                            "Грант предоставляется на конкретную образовательную программу (курс Java-разработчик). От студента мы ожидаем активность и инициативность в прохождении курса. В случае если мы видим, что обучение не происходит, то мы оставляем за собой право на закрытие доступа к курсу."
                        )}
                        {AccordionItem(
                            "Каким образом я пойму, что получу грант?",
                            "В телеграм боте получатели гранта получат сообщение с индивидуальным промокодом на обучение"
                        )}
                        {AccordionItem(
                            "Какова общая сумма гранта и как она будет распределена на мое обучение?",
                            'Грант полностью покрывает обучение по направлению Java-разработчика по плану "Расширенный план"'
                        )}
                        {AccordionItem(
                            "Что от меня ожидают, после получение гранта?",
                            'Прохождение обучения, обратную связь по качеству курса и прохождения стажировки'
                        )}
                        {AccordionItem(
                            "Есть ли какие-либо требования по результатам обучения или академической успеваемости, чтобы сохранить этот грант?",
                            'Да, мы ожидаем прохождение курса в течении 4 месяцев с момента старта программы. Мы оставляем за собой право увеличить этот срок, по запросу студента или полнстью его убрать.'
                        )}

                        {AccordionItem(
                            "Какие документы и сроки необходимы для подачи отчетности о расходовании гранта?",
                            'Мы не требуем каких-либо документов, тк весь прогресс обучения виден на платформе обучения. Мы не считаем необходим в излишней бюрократии'
                        )}

                        {AccordionItem(
                            "Какие дополнительные возможности или ресурсы предоставляются грантом, помимо финансирования?",
                            'Прохождение стажировки на реальных проектах'
                        )}

                        {AccordionItem(
                            "Каковы сроки и процедуры продления гранта, если обучение займет больше времени, чем предполагалось?",
                            'Нужно просто написать куратору. Все заявления мы рассматриваем в индивидуальном порядке'
                        )}

                        {AccordionItem(
                            "Какие ресурсы и поддержка доступны для грантополучателей во время обучения, такие как академические консультации или менторство?",
                            'Доступны все условия по тарифному плану "Расширенный план" в курсе Java-разработчик'
                        )}




                    </>}
                />
                <ReadyToStart
                    idAnchor={'grant'}
                    title={'Получить грант'}
                    subtitle={'Заявка формируется в телеграм боте. Рассмотрение заявки проходит до 16 октября. Грант дает возможность бесплатно пройти обучение по Java направлению'}
                    actionTitle={'Заполнить заявку на грант'}
                    actionLink={"https://t.me/campus_java_bot?start"}
                />
            </div>
        </MainLayout>
    );
};

export default GrantPage;
