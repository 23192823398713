import './style.css';
import Header from "../../../ui/components/header";
import Footer from "../../../ui/components/footer";

function MainLayout({children, menuItemList}) {
    return (
        <div>
            <div id="wrap">

                <div className="lqd-sticky-placeholder hidden"></div>

                <Header menuItemList={menuItemList}></Header>
                <main className="content" id="lqd-site-content">
                    <div id="lqd-contents-wrap">
                        {children}
                    </div>
                </main>

                <Footer></Footer>
            </div>

            <div id="contact-modal" className="lity-modal lqd-modal lity-hide" data-modal-type="fullscreen">
                <div className="lqd-modal-inner">
                    <div className="lqd-modal-head"></div>
                    <section className="lqd-section lqd-modal-content link-black bg-center bg-cover bg-norepeat"
                             style={{"background-image": "url(./assets/images/common/modal-bg.jpeg)"}}>
                        <div className="container min-h-100vh flex items-center flex-wrap">
                            <div className="w-55percent relative flex flex-col sm:w-full">
                                <div
                                    className="flex flex-col items-start justify-center py-10 pr-100 pl-10 module-content">
                                    <div className="ld-fancy-heading">
                                        <h2 className="ld-fh-element mb-0/5em text-120 text-black leading-0/8em font-medium">Send
                                            a message.</h2>
                                    </div>
                                    <div className="ld-fancy-heading">
                                        <p className="ld-fh-element mb-0/5em text-18">We're here to answer any question
                                            you may have.</p>
                                    </div>
                                    <div className="spacer w-full sm:hidden">
                                        <div className="w-full h-140"></div>
                                    </div>
                                    <div className="w-full flex flex-row flex-grow-1 gap-10 module-info">
                                        <div className="w-50percent flex flex-col gap-10">
                                            <div className="ld-fancy-heading relative">
                                                <h6 className="ld-fh-element relative mb-0/5em text-14 font-bold text-black mb-0/5em tracking-0">careers</h6>
                                            </div>
                                            <div className="ld-fancy-heading relative">
                                                <p className="ld-fh-element relative mb-0/5em text-16 leading-1/2em mb-0/5em">Would
                                                    you like to join our growing team?</p>
                                            </div>
                                            <div className="ld-fancy-heading relative">
                                                <p className="ld-fh-element relative mb-0/5em">
                                                    <a href="#"
                                                       className="text-16 font-bold leading-1/2em">careers@hub.com </a>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="w-50percent flex flex-col gap-10 pl-15 sm:pl-0">
                                            <div className="ld-fancy-heading relative">
                                                <h6 className="ld-fh-element relative mb-0/5em text-14 font-bold text-black mb-0/5em tracking-0">Feedbacks</h6>
                                            </div>
                                            <div className="ld-fancy-heading relative">
                                                <p className="ld-fh-element relative mb-0/5em text-16 leading-1/2em mb-0/5em">Have
                                                    a project in mind? Send a message.</p>
                                            </div>
                                            <div className="ld-fancy-heading relative">
                                                <p className="ld-fh-element relative mb-0/5em">
                                                    <a href="#"
                                                       className="text-16 font-bold leading-1/2em">info@hub.com </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-45percent relative flex flex-col sm:w-full p-10 module-form">
                                <div
                                    className="lqd-contact-form lqd-contact-form-inputs-underlined lqd-contact-form-button-lg lqd-contact-form-button-block p-10 sm:p-0">
                                    <div role="form">
                                        <div className="screen-reader-response">
                                            <p role="status" aria-live="polite" aria-atomic="true"></p>
                                        </div>
                                        <form action="./assets/php/mailer.php" method="post" className="lqd-cf-form"
                                              noValidate="novalidate" data-status="init">
                                            <div className="row -mr-15 -ml-15">
                                                <div className="col col-12 col-md-6 px-15">
                                                    <p>
													<span className="lqd-form-control-wrap text">
														<input
                                                            className="text-13 text-black border-black-20 bg-transparent"
                                                            type="text" name="name" value="" size="40"
                                                            aria-required="true" aria-invalid="false"
                                                            placeholder="What's your name?"/>
													</span>
                                                    </p>
                                                </div>
                                                <div className="col col-12 col-md-6 px-15">
                                                    <p>
													<span className="lqd-form-control-wrap email">
														<input
                                                            className="text-13 text-black border-black-20 bg-transparent"
                                                            type="email" name="email" value="" size="40"
                                                            aria-required="true" aria-invalid="false"
                                                            placeholder="Email address"/>
													</span>
                                                    </p>
                                                </div>
                                                <div className="col col-12 col-md-6 px-15">
                                                    <p>
													<span className="lqd-form-control-wrap tel">
														<input
                                                            className="text-13 text-black border-black-20 bg-transparent"
                                                            type="text" name="topic" value="" size="40"
                                                            aria-required="true" aria-invalid="false"
                                                            placeholder="Select a Discussion Topic"/>
													</span>
                                                    </p>
                                                </div>
                                                <div className="col col-12 col-md-6 px-15">
                                                    <p>
													<span className="lqd-form-control-wrap text">
														<input
                                                            className="text-13 text-black border-black-20 bg-transparent"
                                                            type="text" name="budget" value="" size="40"
                                                            aria-required="true" aria-invalid="false"
                                                            placeholder="What's your budget?"/>
													</span>
                                                    </p>
                                                </div>
                                                <div className="col col-12 px-15">
                                                    <p>
													<span className="lqd-form-control-wrap textarea">
														<textarea
                                                            className="text-13 text-black border-black-20 bg-transparent"
                                                            name="message" cols="10" rows="6" aria-required="true"
                                                            aria-invalid="false"
                                                            placeholder="Tell us about your project"></textarea>
													</span>
                                                    </p>
                                                </div>
                                                <div className="col col-12 px-15">
												<span className="lqd-form-control-wrap acceptance">
													<span className="lqd-cf-form-control lqd-cf-acceptance">
														<span className="lqd-cf-list-item">
															<label>
																<input type="checkbox" name="acceptance" value="1"
                                                                       aria-invalid="false"/>
																<span className="lqd-cf-list-item-label">I am bound by the terms of the Service I accept Privacy Policy</span>
															</label>
														</span>
													</span>
												</span>
                                                </div>
                                                <div className="col col-12 text-center px-15">
                                                    <input type="submit" value="Send message"
                                                           className="lqd-cf-form-control text-16 uppercase font-medium leading-1em tracking-1 font-bold bg-primary text-white border-none"/>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="lqd-cf-response-output"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="lqd-modal-foot"></div>
                </div>
            </div>


            <div class="lqd-cc lqd-cc--inner fixed pointer-events-none"></div>
            <div
                class="lqd-cc--el lqd-cc-solid lqd-cc-explore flex items-center justify-center rounded-full fixed pointer-events-none">
                <div class="lqd-cc-solid-bg flex absolute lqd-overlay"></div>
                <div class="lqd-cc-solid-txt flex justify-center text-center relative">
                    <div class="lqd-cc-solid-txt-inner">Explide</div>
                </div>
            </div>
            <div
                class="lqd-cc--el lqd-cc-solid lqd-cc-drag flex items-center justify-center rounded-full fixed pointer-events-none">
                <div class="lqd-cc-solid-bg flex absolute lqd-overlay"></div>
                <div class="lqd-cc-solid-ext lqd-cc-solid-ext-left inline-flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                         style={{width: "1em", height: "1em"}}>
                        <path fill="currentColor"
                              d="M19.943 6.07L9.837 14.73a1.486 1.486 0 0 0 0 2.25l10.106 8.661c.96.826 2.457.145 2.447-1.125V7.195c0-1.27-1.487-1.951-2.447-1.125z"></path>
                    </svg>
                </div>
                <div class="lqd-cc-solid-txt flex justify-center text-center relative">
                    <div class="lqd-cc-solid-txt-inner">Drag</div>
                </div>
                <div class="lqd-cc-solid-ext lqd-cc-solid-ext-right inline-flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
                         style={{"width": "1em", height: "1em"}}>
                        <path fill="currentColor"
                              d="M11.768 25.641l10.106-8.66a1.486 1.486 0 0 0 0-2.25L11.768 6.07c-.96-.826-2.457-.145-2.447 1.125v17.321c0 1.27 1.487 1.951 2.447 1.125z"></path>
                    </svg>
                </div>
            </div>
            <div class="lqd-cc--el lqd-cc-arrow inline-flex items-center fixed top-0 left-0 pointer-events-none">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M60.4993 0V4.77005H8.87285L80 75.9207L75.7886 79.1419L4.98796 8.35478V60.4993H0V0H60.4993Z"/>
                </svg>
            </div>
            <div class="lqd-cc--el lqd-cc-custom-icon rounded-full fixed pointer-events-none">
                <div class="lqd-cc-ci inline-flex items-center justify-center rounded-full relative">
                    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
                         style={{"width": "1em", height: "1em"}}>
                        <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"
                              d="M16.03 6a1 1 0 0 1 1 1v8.02h8.02a1 1 0 1 1 0 2.01h-8.02v8.02a1 1 0 1 1-2.01 0v-8.02h-8.02a1 1 0 1 1 0-2.01h8.02v-8.01a1 1 0 0 1 1.01-1.01z"></path>
                    </svg>
                </div>
            </div>
            <div class="lqd-cc lqd-cc--outer fixed top-0 left-0 pointer-events-none"></div>

            <template id="lqd-temp-snickersbar">
                <div class="lqd-snickersbar flex flex-wrap lqd-snickersbar-in" data-item-id>
                    <div class="lqd-snickersbar-inner flex flex-wrap items-center">
                        <div class="lqd-snickersbar-detail">
                            <p style={{"display": "none"}} class="lqd-snickersbar-addding-temp mt-0mb-0">Adding to
                                cart</p>
                            <p style={{"display": "none"}} class="lqd-snickersbar-added-temp mt-0mb-0">Added to cart</p>
                            <p class="lqd-snickersbar-msg flex items-center mt-0mb-0"></p>
                            <p class="lqd-snickersbar-msg-done flex items-center mt-0mb-0"></p>
                        </div>
                        <div class="lqd-snickersbar-ext ml-1/5em"></div>
                    </div>
                </div>
            </template>
            <template id="lqd-temp-sticky-header-sentinel">
                <div class="lqd-sticky-sentinel invisible absolute pointer-events-none"></div>
            </template>
            <div class="lity" role="dialog" aria-label="Dialog Window (Press escape to close)" tabindex="-1"
                 data-modal-type="default" style={{"display": "none"}}>
                <div class="lity-backdrop"></div>
                <div class="lity-wrap" data-lity-close role="document">
                    <div class="lity-loader" aria-hidden="true">Loading...</div>
                    <div class="lity-container">
                        <div class="lity-content"></div>
                    </div>
                    <button class="lity-close" type="button" aria-label="Close (Press escape to close)"
                            data-lity-close>&times;</button>
                </div>
            </div>
        </div>
    );
};

export default MainLayout;
