

export function IconMenu(iconMenu, title, text, isLast) {
    return (<div
        className={"iconbox flex relative z-2 iconbox-circle  mb-50 text-left " + (isLast == true ? "" : "iconbox-icon-linked")}>
        <div className="iconbox-icon-wrap relative mr-25">
            <div
                className="iconbox-icon-container inline-flex relative z-1 rounded-full items-center justify-center w-40 h-40 text-16 text-primary bg-purple-100">
                <i aria-hidden="true"
                   className={"lqd-icn-ess text-1em " + iconMenu}></i>
            </div>
        </div>
        <div className="contents">
            <h3 className="lqd-iconbox-heading text-20 mt-0/5em mb-1em text-black leading-1em">
                {title}
            </h3>
            <p className="text-16 leading-23 text-slate-600">
                {text}
            </p>
        </div>
    </div>);
}

function HowItWork({
                       tagTitle,
    title,
    imgContent,
    wayItems
                   }) {
    return (<section
        className="lqd-section process flex flex-col items-center justify-center transition-all rounded-30 shadow-md bg-white pt-40"
        id="how-it-work" data-custom-animations="false"
        data-ca-options='{"animationTarget": ".animation-element, figure, .iconbox", "duration" : 850, "startDelay" : 300, "delay" : 75, "ease": "expo.out", "initValues": {"y": "30px", "scaleX" : 0.8, "scaleY" : 0.8, "opacity" : 0} , "animations": {"y": "0px", "scaleX" : 1, "scaleY" : 1, "opacity" : 1}}'>
        <div
            className="ld-fancy-heading relative flex self-center text-center bg-transparent rounded-100 animation-element"
            style={{"background-image": "linear-gradient(90deg, #E3C4E9 0%, #EAEDFB 100%)"}}>
            <h6 className="ld-fh-element inline-block relative py-5 px-15 m-0">
                {tagTitle}
            </h6>
        </div>
        <div className="spacer w-full animation-element">
            <div className="w-full h-20"></div>
        </div>
        <div className="ld-fancy-heading relative flex text-center animation-element">
            <h2 className="ld-fh-element inline-block relative ld-gradient-heading bg-transparent mb-0/2em pb-0/2em"
                style={{"background-image": "linear-gradient(180deg, #7F5BC4 0%, #60A3E6 100%)"}}>
                {title}
            </h2>
        </div>
        <div className="max-w-full absolute z-1 top-155 module-arrow"
             data-custom-animations="false"
             data-ca-options='{"animationTarget": "figure", "ease": "expo.out", "initValues": {"x": "-50px", "y": "-50px", "scaleX" : 0, "scaleY" : 0} , "animations": {"x": "0px", "y": "0px", "scaleX" : 1, "scaleY" : 1}}'>
            <div className="lqd-imggrp-single block relative">
                <div
                    className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                        <img className="w-75 h-auto" width="72"
                             src="/assets/images/demo/start-hub-x/process/arrow.png"
                             alt="arrow"/>
                    </figure>
                </div>
            </div>
        </div>
        <div className="spacer w-full animation-element">
            <div className="w-full h-20"></div>
        </div>
        <div
            className="w-full flex flex-row border-top border-black-10 pt-10 px-10 pb-0 sm:flex-col-reverse">


            <div className="w-50percent flex flex-col py-10 pr-20 pl-70 sm:w-full module-col">
                {wayItems}


            </div>
            <div className="w-50percent rounded-30 sm:w-full">
                <div className="lqd-imggrp-single block relative">
                    <div
                        className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                        <figure className="w-full relative">
                            <img className=" rounded-30" width="995" height="952"
                                 src={imgContent}
                                 alt="process"/>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </section>);
}

export default HowItWork;