export function greyListItem(title) {
    return (
        <div
            className="iconbox flex flex-grow-1 relative iconbox-circle text-left mb-10">
            <div
                className="iconbox-icon-wrap mr-15 text-16 w-20 h-20 flex items-center justify-center text-black bg-gray-200 rounded-full">
                <div
                    className="iconbox-icon-container inline-flex relative z-1 rounded-full">
                    <i aria-hidden="true"
                       className="lqd-icn-ess icon-ion-ios-add"></i>
                </div>
            </div>
            <div
                className="lqd-iconbox-heading text-15 font-medium -mt-0/1em text-black-50">
                {title}
            </div>
        </div>
    );
}

export function colorFullListItem(title) {
    return (
        <div className="iconbox flex flex-grow-1 relative iconbox-circle text-left mb-10">
            <div
                className="iconbox-icon-wrap mr-15 text-12 w-20 h-20 flex items-center justify-center text-purple-500 bg-purple-100 rounded-full">
                <div className="iconbox-icon-container inline-flex relative z-1 rounded-full">
                    <i aria-hidden="true" className="lqd-icn-ess icon-ion-ios-checkmark"></i>
                </div>
            </div>
            <div className="lqd-iconbox-heading text-15 font-medium -mt-0/1em text-black">
                {title}
            </div>
        </div>
    );
}

function Compare({
                     tagTitle,
                     title,
                     subtitle,
                     colTitle1,
                     colTitle2,
                     colItems1,
                     colItems2
                 }) {
    return (<section
        className="lqd-section compare flex flex-col items-center justify-center transition-all rounded-30 shadow-md bg-white bg-center bg-no-repeat py-40"
        id="compare" style={{"background-image": "url(./assets/images/demo/start-hub-x/bg/map.png)"}}
        data-custom-animations="false"
        data-ca-options='{"animationTarget": ".animation-element, .iconbox", "duration" : 850, "startDelay" : 300, "delay" : 75, "ease": "expo.out", "initValues": {"y": "40px", "scaleX" : 0.8, "scaleY" : 0.8, "opacity" : 0} , "animations": {"y": "0px", "scaleX" : 1, "scaleY" : 1, "opacity" : 1}}'>
        <div
            className="ld-fancy-heading relative flex self-center text-center bg-transparent rounded-100 animation-element"
            style={{"background-image": "linear-gradient(90deg, #E3C4E9 0%, #EAEDFB 100%)"}}>
            <h6 className="ld-fh-element inline-block relative py-5 px-15 m-0">
                {tagTitle}
            </h6>
        </div>
        <div className="spacer w-full animation-element">
            <div className="w-full h-20"></div>
        </div>
        <div className="flex flex-row items-end justify-center gap-10">
            <div className="ld-fancy-heading relative flex text-center animation-element">
                <h2 className="ld-fh-element inline-block relative ld-gradient-heading bg-transparent m-0 pb-0/2em"
                    style={{"background-image": "linear-gradient(180deg, #7F5BC4 0%, #60A3E6 100%)"}}>
                    {title}
                </h2>
            </div>
            <div className="ld-fancy-heading relative animation-element">
                                        <span
                                            className="ld-fh-element inline-block relative text-52 leading-1em mb-0/2em">🤘🏽</span>
            </div>
        </div>
        <div className="ld-fancy-heading relative lg:text-center animation-element">
            <p className="ld-fh-element inline-block relative mb-0/5em text-17 leading-24 font-medium text-black">
                {subtitle}
            </p>
        </div>
        <div className="spacer w-full animation-element">
            <div className="w-full h-20"></div>
        </div>
        <div
            className="w-full flex flex-wrap justify-evenly border-top border-black-10 px-10 pt-10 pb-0">
            <div
                className="w-30percent flex flex-col gap-12 my-10 p-10 transition-bg sm:w-full">
                <div
                    className="ld-fancy-heading relative flex self-start bg-gray-100 rounded-100 animation-element">
                    <h6 className="ld-fh-element inline-block relative text-12 font-semibold m-0 py-5 px-15">
                        {colTitle1}
                    </h6>
                </div>
                <div className="spacer w-full animation-element">
                    <div className="w-full h-10"></div>
                </div>
                {colItems2}

            </div>
            <div
                className="w-30percent flex flex-col gap-12 my-10 p-10 transition-bg sm:w-full animation-element">
                <div
                    className="ld-fancy-heading relative flex self-start rounded-100 bg-transparent"
                    style={{"background-image": "linear-gradient(90deg, #7F5BC4 0%, #60A3E6 100%)"}}>
                    <h6 className="ld-fh-element inline-block relative text-12 text-white font-semibold m-0 py-5 px-15">
                        {colTitle2}
                    </h6>
                </div>
                <div className="spacer w-full animation-element">
                    <div className="w-full h-10"></div>
                </div>
                {colItems1}

            </div>
        </div>
        <div className="w-full flex items-center justify-center p-10">
            <div
                className="ld-fancy-heading relative flex self-center border-1 border-black-10 rounded-10 animation-element">
                <p className="ld-fh-element inline-block relative m-0 text-12 text-black py-10 px-15">
                    <span>Мы так же можем ответить на вопросы в телеграм</span>
                </p>
            </div>
        </div>
    </section>);
}

export default Compare;